import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="row">
      <div className="col">
        <h1>A página que você procura não foi encontrada...</h1>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
